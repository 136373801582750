import React from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

import * as S from './popup-with-advert.style';
import styles from './popup-with-advert.module.scss';

export interface PopupProps {
    open: boolean;
    onContinue?(): void;
    onClose(): void;
    children?: JSX.Element | JSX.Element[];
    title?: string;
    disableCloseIcon?: boolean;
}

export const PopupWithAdvert = ({ children, open, onClose, title, disableCloseIcon }: PopupProps): JSX.Element => {
    const Header = () => {
        return (
            <S.Header>
                <div className={styles.title}>{title}</div>
            </S.Header>
        );
    };

    return (
            <Modal
                showCloseIcon={!disableCloseIcon}
                classNames={{ modal: styles.modal, closeIcon: styles.closeIcon, overlay: styles.overlay }}
                open={open}
                onClose={onClose}
                center
            >
                {title && <Header />}

                <S.Content>{children}</S.Content>
            </Modal>
    );
};
