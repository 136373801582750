import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.popup.headerBg};
    color:  ${({ theme }) => theme.popup.headerText};
    padding: 1rem;
`;

export const Title = styled.div`
    flex: auto;
    font-size: 1.2rem;
`;

export const Content = styled.div`
    //padding: 1rem;
    //max-width: 25rem;
`;
