let isIdentityLocalDev = false;
let isCoreLocalDev = false;
let isIntegrationLocalDev = false;
let isRentChiefUiLocalDev = true;

let rootDomainIdentityDev = !isIdentityLocalDev
  ? `https://rc-services-identity-dev-ase.azurewebsites.net`
  : `https://localhost:5001`;
let rootDomainCoreDev = !isCoreLocalDev
  ? "https://rc-services-core-dev-ase.azurewebsites.net"
  : "https://localhost:44344";
let rootDomainIntegrationsDev = !isIntegrationLocalDev
  ? "https://rc-services-integrations-dev-ase.azurewebsites.net"
  : "https://localhost:44305";
let rootRentChiefUiUrlLocalhost = !isRentChiefUiLocalDev
  ? "https://dev.rentchief.co.uk"
  : "http://localhost:3000";
let rootRentChiefResearchUiUrlLocalhost = !isRentChiefUiLocalDev
  ? "https://research-dev.rentchief.co.uk"
  : "http://localhost:3001";

export const environment = process.env.REACT_APP_ENVIRONMENT || "localhost";
export const environmentName = process.env.NODE_ENV;

export const rootDomainCore =
  process.env.REACT_APP_ROOT_DOMAIN_CORE || rootDomainCoreDev;
export const rootDomainIdentity =
  process.env.REACT_APP_ROOT_DOMAIN_IDENTITY || rootDomainIdentityDev;
export const rootDomainIntegrations =
  process.env.REACT_APP_ROOT_DOMAIN_INTEGRATIONS || rootDomainIntegrationsDev;
export const rootDomainRcUi =
  process.env.REACT_APP_ROOT_DOMAIN_UI || rootRentChiefUiUrlLocalhost;
export const rootRcResearchUiUrl =
  process.env.REACT_APP_RC_RESEARCH_UI_URL || rootRentChiefResearchUiUrlLocalhost;

// Core service
export const landRegistryService = `${rootDomainCore}/api/v1/LandRegistry`;
export const calculationService = `${rootDomainCore}/api/v1/CalculationV2/calculate`;

// Integrations
export const findAddressService = `${rootDomainIntegrations}/api/v1/address/find/uk`;
