import React from "react";
import * as S from "./embed-test.styles";

const EmbedTest = () => (
  <iframe
    onLoad={(e) => {
      e.currentTarget.style.height = `${e.currentTarget.contentWindow.document.body.scrollHeight}px`
    }}
    style={{
      border: "none",
      overflow: "hidden",
      height: "100%",
    }}
    src="http://localhost:3001/calculator-simple-entry?theme=recognise"
    title="Research"
    scrolling="no"
  />
  // <S.Container>
  //   <div>
  //     <h1>Postcode Search</h1>
  //     <iframe
  //       style={{
  //         border: "none",
  //         overflow: "hidden",
  //         height: "100%",
  //       }}
  //       src="http://localhost:3000/postcode-search?theme=recognise"
  //       title="Research"
  //       scrolling="no"
  //     />
  //   </div>

  //   <div>
  //     <h1>Calculator Simple Entry</h1>
  //     <iframe
  //       style={{
  //         border: "none",
  //         overflow: "hidden",
  //         height: "100%",
  //       }}
  //       src="http://localhost:3000/calculator-simple-entry?theme=recognise"
  //       title="Research"
  //       scrolling="no"
  //     />
  //   </div>

  // </S.Container>
);

export { EmbedTest };
