import { RedirectPopup, ResearchContext } from "@rentchief/components";
import React, { useContext, useState } from "react";
import { ReactComponent as Logo } from "../../images/rent_chief_logo.svg";
import RedirectModal from "../RedirectModal/RedirectModal"

import styles from "./Footer.module.scss";

const Footer = () => {
  const { config } = useContext(ResearchContext);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  const onClick = () => {
      if(config?.redirect_popup?.show_redirect_popup) {
          setDisclaimerOpen(true);
          return;
      }

      onNavigate();
  }

  const onNavigate = () => window?.open("https://app.rentchief.co.uk", "_blank");

  return (
    <footer className={styles.footer}>
      {config?.redirect_popup?.show_redirect_popup && (
        <RedirectModal
          title="You're leaving..."
          address={null}
          open={disclaimerOpen}
          onClose={() => setDisclaimerOpen(false)}
        />
      )}

      <p>Powered by</p>
      <div
        onClick={() => onClick()}
        className={styles.imageWrapper}
      >
        <Logo />
      </div>
    </footer>
  );
};

export default Footer;
