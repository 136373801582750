import {
  ApiContext,
  Button,
  IAddress,
  IROICalculatorData,
  Popup,
  PopupProps,
  ResearchContext
} from "@rentchief/components";
import React, { useContext, useEffect, useState } from "react";
import styles from "./RedirectModal.module.scss";
import { PopupWithAdvert } from "../PopupWithAdvert/popup-with-advert";
import ReactHtmlParser from "react-html-parser";
import {convertNodeToElement} from "react-html-parser"
//import "./RedirectModal.css"
const { BlobServiceClient } = require("@azure/storage-blob");

const RedirectModal = (
  props: PopupProps & { address: IAddress } & IROICalculatorData
) => {
  const { base_rc_ui_url } = useContext(ApiContext);
  const { config } = useContext(ResearchContext);
  const [popupHtml, setPopupHtml] = useState(null);
  const [popupHtmlMobile, setPopupHtmlMobile] = useState(null); 
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  useEffect(() => {
    const blobName = (config.redirect_popup.redirect_text != undefined) 
                    ? config.redirect_popup.redirect_text + ".html" 
                    : "client/rentChief/html/ResearchPopupSaveAdvert.html";
    download(blobName, true);

    const blobNameMobile = (config.redirect_popup.redirect_text != undefined) 
                          ? config.redirect_popup.redirect_text + "_Mobile.html"
                          : "client/rentChief/html/ResearchPopupSaveAdvert_Mobile.html";
    download(blobNameMobile, false);
    //setPopupHtmlMobile("<div>This is a test</div>")
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > 400);
  };

  const generatePropertyName = () => {
    let result = "";
    result = appendToAddress(props.address?.line2, result);
    result = appendToAddress(props.address?.line1, result);
    result = appendToAddress(props.address?.street_Name, result);
    result = appendToAddress(props.address?.postcode, result);

    return result;
  };

  const appendToAddress = (addressLine: string, appendedAddress: string) => {
    if (addressLine) {
      if (appendedAddress) {
        appendedAddress += `, ${addressLine}`;
      } else {
        appendedAddress += addressLine;
      }
    }

    return appendedAddress;
  }

  const onContinue = () => {
    if(props.address != null || props.address != undefined)
    {
      let obj = {
        propertyName: generatePropertyName(),
        address: props.address?.postcode ? props.address : null,
        purchasePrice: props.purchaseCost,
        monthlyRentalAmount: props.monthlyRentalAmount,
        investmentAmount: props.investmentAmount,
        deposit: props.deposit,
        mortgageAmount: props.mortgageAmount,
        yearsOfInvestment: props.yearsOfInvestment,
        dateOfPurchase: new Date(),
        ltv: props.ltv,
        interestRate: props.interestRate,
        valuationFee: props.valuationFee,
        mortgageFee: props.mortgageFee,
        refurbishment: props.refurbishment,
        stampDuty: props.stampDuty,
        lettingAgent: props.lettingAgent,
        maintenance: props.maintenance,
        voidPeriod: props.voidPeriod,
        insurance: props.insurancePCM,
        capitalGrowthRate: props.capitalGrowthRate,
        rentalGrowthRate: props.rentalGrowthRate,
        serviceChargesPCM: props.serviceChargesPCM,
        groundRentPCM: props.groundRentPCM,
        solicitorFeeAndSearches: props.solicitorFeeAndSearches,
        investmentAmountPercent: props.investmentAmountPercent,
        mortgagePCM: props.mortgagePCM,
        mortgageType: props.mortgageType,
        mortgageTerm: props.mortgageTerm,
        lettingAgentPercent: props.lettingAgentPercent,
        maintenancePercent: props.maintenancePercent,
        voidPeriodPercent: props.voidPeriodPercent,
        refurbishmentPercent: props.refurbishmentPercent,
        otherPurchaseCosts: props.otherPurchaseCosts,
        otherOperatingCosts: props.otherOperatingCosts,
        country: props.country,
        stampDutyLocale: props.stampDutyLocale,
        isInvestmentProperty: props.isInvestmentProperty,
        insurancePCM: props.insurancePCM,
        renovationPeriodMonths: props.renovationPeriodMonths,
        propertyValueAfterRenovation: props.propertyValueAfterRenovation,
        investorStrategy: props.investorStrategy,
        clientName: props.clientName
      };
  
      /* let fullUrl = config.whitelabelClientId != undefined 
        ? `${base_rc_ui_url}?addProperty=${JSON.stringify(obj)}&whitelabelId=${config.whitelabelClientId}` 
        : `${base_rc_ui_url}?addProperty=${JSON.stringify(obj)}`; */
      let fullUrl = `${base_rc_ui_url}?addProperty=${JSON.stringify(obj)}`;
      window.open(fullUrl, "_blank");
    }
    else
    {
      /* let fullUrl = config.whitelabelClientId != undefined 
        ? `${base_rc_ui_url}?whitelabelId=${config.whitelabelClientId}` 
        : base_rc_ui_url; */
        let fullUrl = base_rc_ui_url;
      window.open(fullUrl, "_blank");
    }

  };

  const htmlParserTransform = (node, index) => {
    if (node.type == "tag" && node.name == "div" && node.attribs.id == "onContinueButton") {
      return (
        <>
          <Button className={styles.button} onClick={onContinue}>
            Continue
          </Button>
          {convertNodeToElement (node, index, htmlParserTransform)}
        </>
      )
    }
  }

  const download = async(blobName: string, isDesktop: boolean) => {
    const { BlobServiceClient } = require("@azure/storage-blob");
 
    const account = "rentchiefstorageuk";
    const sas = "?sp=r&st=2022-12-05T11:19:24Z&se=2032-12-05T19:19:24Z&spr=https&sv=2021-06-08&sr=c&sig=QWU1RqgVA4ZN%2B24Pe0ICl6X1ZunxZxPznrumbwqb0Xk%3D";
    const containerName = "config";

    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
   
    // Get blob content from position 0 to the end
    // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    isDesktop ? setPopupHtml(downloaded) : setPopupHtmlMobile(downloaded);
    
    // [Browsers only] A helper method used to convert a browser Blob into string.
    async function blobToString(blob) {
      const fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
          resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
      });
    }
  }
  

  return (
    <PopupWithAdvert {...props} title={props.title}>
      {
        isDesktop
        ? ReactHtmlParser(popupHtml, {transform: htmlParserTransform})
        : ReactHtmlParser(popupHtmlMobile, {transform: htmlParserTransform})
      }
    </PopupWithAdvert>
  );
};

export default RedirectModal;
