import {
  ApiContext,
  CalculationService,
  AddressService,
  LandRegistryService,
  CalculatorHeader,
  deriveClientFromUrl,
  IResearchConfig,
  IResearchContextData,
  IROICalculatorData,
  ResearchAddress,
  ResearchBannerImage,
  ResearchContext,
  ResearchSelectedProperty,
  ROICalculator,
  TabNavigation,
} from "@rentchief/components";
import "@rentchief/components/dist/style.css";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";
import styles from "./app.module.scss";
import * as S from './App.styles';
import DisclaimerFooter from "./Components/DisclaimerFooter/DisclaimerFooter";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import Footer from "./Components/Footer/Footer";
import RedirectModal from "./Components/RedirectModal/RedirectModal";
import GeneralModal from "./Components/GeneralRedirectModal/GeneralRedirectModal";
import * as domains from "./configuration/domains";
import { CalculatorEntryPage } from "./pages/calculator-entry/calculator-entry.page";
import { EmbedTest } from "./pages/embed-test/embed-test.page";
import { PostcodeSearchPage } from "./pages/postcode-search/postcode-search.page";

function App() {
  let location = useLocation();
  const [data, setData] = useState<IResearchContextData>(null);
  const [calculatorData, setCalculatorData] = useState<IROICalculatorData>(
    null
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    dateFrom: "01-01-1900",
    dateTo: "01-01-2021",
    selected: "All years",
  });
  const [config, setConfig] = useState<IResearchConfig>(null);
  const [theme, setTheme] = useState(null);
  const [addingProperty, setAddingProperty] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogName, setShowDialogName] = useState(null);
  const [dialogProperties, setDialogProperties] = useState(null);
  const [currentClient, setCurrentClient] = useState("rentchief");
  const [addressForRedirect, setAddressForRedirect] = useState(null);
  const calculationService = new CalculationService(
    `${domains.calculationService}`
  );
  const addressService = new AddressService(
    `${domains.findAddressService}`
  );
  const landRegistryService = new LandRegistryService(
    `${domains.landRegistryService}`
  );

  useEffect(() => {
    // Get the client from the URL using helper
    let client = null;
    const clients = ["recognise", "lloyds", "direct-line", "rentchief", "sjfinancial", "mortgagesforbusiness", "barclays", "mint", "shawbrook", "charleston"];

    // THIS WILL BE RETRIEVED FROM API AT SOME POINT
    const urlClient = deriveClientFromUrl(clients);

    if (urlClient) client = urlClient;

    const query = new URLSearchParams(location.search).get("theme");
    if (query && clients.includes(query)) client = query;

    // default set to Rent Chief
    if (!client) client = "rentchief";
    setCurrentClient(client);

    // FILE RETRIVAL WILL BE MOVED OUT OF RESEARCH APP AND RETRIVED FROM AN API
    const config = require(`./configuration/clients/${client}/config.json`);
    const theme = require(`./configuration/clients/${client}/theme.json`);
    setConfig(config);
    setTheme(theme);
  }, []);

  if (!config || !theme) return null;

  return (
    <ThemeProvider theme={theme}>
      <S.AppContainerDiv>
        <Switch>
            <ApiContext.Provider
              value={{
                base_rc_ui_url: domains.rootDomainRcUi,
                base_rc_research_ui_url: currentClient != "recognise" ? domains.rootRcResearchUiUrl : "https://research.recognisebank.co.uk",
                calculation_service: `${domains.rootDomainCore}/api/v1/CalculationV2/calculate`,
                land_registry_service: domains.landRegistryService,
                address_service: `${domains.rootDomainIntegrations}/api/v1/address/find/uk`,
              }}
            >
              <Route path="/postcode-search" component={PostcodeSearchPage} />

              <Route
                path="/calculator-simple-entry"
                component={CalculatorEntryPage}
              />

              <Route path="/embed-test" component={EmbedTest} />

              <ResearchContext.Provider
                value={{
                  data,
                  calculatorData,
                  setData,
                  setCalculatorData,
                  onAddProperty: (address, calculationData) => {
                    setCalculatorData(calculationData);
                    setAddingProperty(true);
                    setAddressForRedirect(address);
                  },
                  onShowDialog: (dialogName, properties) => {
                    setShowDialog(true);
                    setShowDialogName(dialogName);
                    setDialogProperties(properties);
                  },
                  filtering: {
                    selected_date_filter: selectedDateFilter,
                    setDateFilter: setSelectedDateFilter,
                  },
                  config,
                  services: {
                    calculationService,
                    addressService,
                    landRegistryService
                  },
                  routes: {
                    researchProperty: `/property`,
                    research: `/`,
                    dealCalculator: `/calculator`,
                  },
                }}
              >
                <Helmet>
                  <link rel="icon" type="image/png" href={config?.meta?.favicon} />
    {/*               <link
                    rel="shortcut icon"
                    href={`${config?.meta?.rootImageUrl}/favicon.ico`}
                  />
                  <link
                    rel="icon"
                    sizes="16x16 32x32 64x64"
                    href={`${config?.meta?.rootImageUrl}/favicon.ico`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="196x196"
                    href={`${config?.meta?.rootImageUrl}/favicon-192.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="160x160"
                    href={`${config?.meta?.rootImageUrl}/favicon-160.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="96x96"
                    href={`${config?.meta?.rootImageUrl}/favicon-96.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="64x64"
                    href={`${config?.meta?.rootImageUrl}/favicon-64.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${config?.meta?.rootImageUrl}/favicon-32.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${config?.meta?.rootImageUrl}/favicon-16.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    href={`${config?.meta?.rootImageUrl}/favicon-57.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href={`${config?.meta?.rootImageUrl}/favicon-114.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href={`${config?.meta?.rootImageUrl}/favicon-72.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href={`${config?.meta?.rootImageUrl}/favicon-144.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href={`${config?.meta?.rootImageUrl}/favicon-60.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href={`${config?.meta?.rootImageUrl}/favicon-120.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href={`${config?.meta?.rootImageUrl}/favicon-76.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href={`${config?.meta?.rootImageUrl}/favicon-152.png`}
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${config?.meta?.rootImageUrl}/favicon-180.png`}
                  /> */}
                </Helmet>

                <RedirectModal
                  {...calculatorData}
                  title={addressForRedirect != null || addressForRedirect != undefined ? "Save property" : "You're leaving..."}
                  address={addressForRedirect}
                  open={addingProperty}
                  onClose={() => setAddingProperty(false)}
                />

                <GeneralModal 
                  title="You're leaving..."
                  open={showDialog}
                  onClose={() => setShowDialog(false)}
                />

                {location.pathname.indexOf("calculator-simple-entry") < 0 &&
                  location.pathname.indexOf("postcode-search") < 0 && (
                    <div className="App">
                      <ResearchBannerImage />

                      <TabNavigation
                        className={styles.nav}
                        items={
                          currentClient != "recognise" ?
                          [
                            {
                              name: "Area Research",
                              route: "/",
                              match: "/",
                              indexMatch: ["property"],
                            },
                            {
                              name: "Deal Calculator",
                              route: "calculator",
                              indexMatch: ["calculator"],
                            },
                          ]
                          :
                          [
                            {
                              name: "Area Research",
                              route: "/",
                              match: "/",
                              indexMatch: ["property"],
                            },
                            {
                              name: "Property Investment Calculator",
                              route: "calculator",
                              indexMatch: ["calculator"],
                            },
                          ]
                        }
                      />

                      <ErrorBoundary>
                        <Route
                          path="/"
                          render={() => (
                            <div className={styles.addressPage}>
                              <ResearchAddress />
                            </div>
                          )}
                          exact
                        />

                        <Route
                          path="/property"
                          render={() => (
                            <div className={styles.addressPage}>
                              <ResearchSelectedProperty />
                            </div>
                          )}
                        />

                        <Route
                          path="/calculator"
                          render={() => (
                            <div>
                              <div className={styles.header}>
                                <CalculatorHeader />
                              </div>
                              <div className={styles.wrapper}>
                                <ROICalculator />
                              </div>
                            </div>
                          )}
                        />
                      </ErrorBoundary>

                      {location.pathname.indexOf("calculator") < 0 && (
                        <DisclaimerFooter />
                      )}
                      <Footer />
                    </div>
                  )}
              </ResearchContext.Provider>
            </ApiContext.Provider>
          </Switch>
      </S.AppContainerDiv>
      
    </ThemeProvider>
  );
}

export default App;
