import { ApiContext, Button, RCCurrencyInput } from "@rentchief/components";
import { ErrorMessage, Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import * as S from "./calculator-entry.styles";

const CalculatorEntrySchema = Yup.object().shape({
  purchasePrice: Yup.string().nullable().required("Purchase Price is required"),
});

const CalculatorEntryPage = () => {
  const { base_rc_research_ui_url } = useContext(ApiContext);

  const onSubmit = ({ purchasePrice, monthlyRentalAmount }) => {
    let query = null;
    if (purchasePrice) query = `?purchasePrice=${purchasePrice}`;
    if (monthlyRentalAmount)
      query += monthlyRentalAmount
        ? `&monthlyRentalAmount=${monthlyRentalAmount}`
        : ``;

    var url =
      window.location != window.location
        ? document.referrer
        : document.location;

    let params = new URL(String(url)).search;
    let searchParams = new URLSearchParams(params);

    let theme = "";
    if (searchParams.has("theme")) {
      theme = `&theme=${searchParams.get("theme")}`;
    }

    window.open(
      `${base_rc_research_ui_url}/calculator${query}${theme}`,
      "_blank"
    );
  };

  const onPropertyPriceChange = (propertyPrice: number) => {
    if (propertyPrice) {
      return propertyPrice * 0.004;
    } else {
      return 0;
    }
  };

  return (
    <Formik
      initialValues={{ purchasePrice: null, monthlyRentalAmount: null }}
      onSubmit={(v) => onSubmit(v)}
      validationSchema={CalculatorEntrySchema}
    >
      {(
        props: FormikProps<{
          purchasePrice: number;
          monthlyRentalAmount: number;
        }>
      ) => (
        <S.Form>
          <S.Fields>
            <label>Purchase Price</label>
            <RCCurrencyInput
              prefix={"£"}
              id="purchasePrice"
              value={props.values.purchasePrice}
              handleChange={(e) => {
                let monthlyRental = onPropertyPriceChange(e);
                props.setFieldValue("purchasePrice", e);
                props.setFieldValue("monthlyRentalAmount", monthlyRental);
              }}
            />
            <S.Error>
              <ErrorMessage name="purchasePrice" />
            </S.Error>

            <label>Monthly Rental Amount</label>
            <RCCurrencyInput
              prefix={"£"}
              id="monthlyRental"
              value={props.values.monthlyRentalAmount}
              handleChange={(e) =>
                props.setFieldValue("monthlyRentalAmount", e)
              }
            />
            <S.Error>
              <ErrorMessage name="monthlyRentalAmount" />
            </S.Error>
          </S.Fields>
          <Button type="submit" onClick={() => props.submitForm()}>
            Calculate
          </Button>
        </S.Form>
      )}
    </Formik>
  );
};

export { CalculatorEntryPage };
