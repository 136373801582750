import {
  ApiContext,
  Button,
  IAddress,
  IROICalculatorData,
  Popup,
  PopupProps,
  ResearchContext
} from "@rentchief/components";
import React, { useContext, useEffect, useState } from "react";
import styles from "./GeneralRedirectModal.module.scss";
import { PopupWithAdvert } from "../PopupWithAdvert/popup-with-advert";
import ReactHtmlParser from "react-html-parser";
import {convertNodeToElement} from "react-html-parser"
//import "./GeneralRedirectModal.css"
const { BlobServiceClient } = require("@azure/storage-blob");

const GeneralRedirectModal = (
  props: PopupProps
) => {
  const { base_rc_ui_url } = useContext(ApiContext);
  const { config } = useContext(ResearchContext);
  const [popupHtml, setPopupHtml] = useState(null);
  const [popupHtmlMobile, setPopupHtmlMobile] = useState(null); 
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  useEffect(() => {
    const blobName = (config.redirect_popup.redirect_text != undefined) 
                    ? config.redirect_popup.redirect_text + ".html" 
                    : "client/rentChief/html/ResearchPopupSaveAdvert.html";
    download(blobName, true);

    const blobNameMobile = (config.redirect_popup.redirect_text != undefined) 
                          ? config.redirect_popup.redirect_text + "_Mobile.html"
                          : "client/rentChief/html/ResearchPopupSaveAdvert_Mobile.html";
    download(blobNameMobile, false);
    //setPopupHtmlMobile("<div>This is a test</div>")
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > 400);
  };

  const onContinue = () => {
      window.open(
        "https://www.rentchief.co.uk/investorresearchtools",
        "_blank"
      );
  };

  const htmlParserTransform = (node, index) => {
    if (node.type == "tag" && node.name == "div" && node.attribs.id == "onContinueButton") {
      return (
        <>
          <Button className={styles.button} onClick={onContinue}>
            Continue
          </Button>
          {convertNodeToElement (node, index, htmlParserTransform)}
        </>
      )
    }
  }

  const download = async(blobName: string, isDesktop: boolean) => {
    const { BlobServiceClient } = require("@azure/storage-blob");
 
    const account = "rentchiefstorageuk";
    const sas = "?sp=r&st=2022-12-05T11:19:24Z&se=2032-12-05T19:19:24Z&spr=https&sv=2021-06-08&sr=c&sig=QWU1RqgVA4ZN%2B24Pe0ICl6X1ZunxZxPznrumbwqb0Xk%3D";
    const containerName = "config";

    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
   
    // Get blob content from position 0 to the end
    // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    isDesktop ? setPopupHtml(downloaded) : setPopupHtmlMobile(downloaded);
    
    // [Browsers only] A helper method used to convert a browser Blob into string.
    async function blobToString(blob) {
      const fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
          resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
      });
    }
  }
  

  return (
    <PopupWithAdvert {...props} title={props.title}>
      {
        isDesktop
        ? ReactHtmlParser(popupHtml, {transform: htmlParserTransform})
        : ReactHtmlParser(popupHtmlMobile, {transform: htmlParserTransform})
      }
    </PopupWithAdvert>
  );
};

export default GeneralRedirectModal;
