import styled from "styled-components";

export const Form = styled.form`
  padding: 1rem;

  label {
    text-align: left;
    display: block;
  }

  input {
    text-transform: uppercase;
    margin-top: 0.5rem;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 32px;
    box-sizing: border-box;
    margin-top: 6px;
    resize: vertical;
    text-align: left;
    font-weight: normal;
    height: 41px;
    font-size: 1rem;

    ::-webkit-input-placeholder {
      opacity: 0.75;
      text-transform: none;
    }
    :-moz-placeholder {
      opacity: 0.75;
      text-transform: none;
    }
    ::-moz-placeholder {
      opacity: 0.75;
      text-transform: none;
    }
    :-ms-input-placeholder {
      opacity: 0.75;
      text-transform: none;
    }
    ::placeholder {
      opacity: 0.75;
      text-transform: none;
    }
  }

  button {
    width: 100%;
    padding: 12px 20px;
    border: none;
    border-radius: 32px;
    cursor: pointer;
  }
`;

export const Fields = styled.div`
  margin-bottom: 16px;
`;

export const Error = styled.div`
  text-align: left;
  font-size: 0.8rem;
  color: red;
`;
