import styled from 'styled-components';

export const AppContainerDiv = styled.div`
    font-family: ${({ theme }) => theme.app?.fontFamily ? theme.app?.fontFamily : 'Red Hat Display'};

    .e-grid, .e-control, .e-css {
        font-family: ${({ theme }) => theme.app?.fontFamily ? theme.app?.fontFamily : 'Red Hat Display'} !important;
      }
      
    input {
        font-family: ${({ theme }) => theme.app?.fontFamily ? theme.app?.fontFamily : 'Red Hat Display'} !important;
    }
`;