import React from 'react'
import styles from './DisclaimerFooter.module.scss'

const DisclaimerFooter = () => {
    return (
        <div className={styles.disclaimer}>
            <p>
                Source acknowledgement: House price data produced by Land
                Registry.
            </p>

            <p>
                This material was last updated on 7 June 2021. It covers the
                period from 1 January 1995 to 27 April 2021 and contains
                property transactions which have been registered during that
                period.
            </p>

            <p>
                Contains HM Land Registry data &copy; Crown copyright and
                database right 2021. This data is licensed under the Open
                Government Licence v3.0
            </p>
        </div>
    )
}

export default DisclaimerFooter
