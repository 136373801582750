import { ApiContext, Button, Input } from "@rentchief/components";
import { ErrorMessage, Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import * as S from "./postcode-search.styles";

const PostcodeValidationSchema = Yup.object().shape({
  postcode: Yup.string().nullable().required("Postcode is required"),
});

const PostcodeSearchPage = () => {
  const { base_rc_research_ui_url } = useContext(ApiContext);

  const onSubmit = (postcode: string) => {
    if (!postcode) return;

    var url =
      window.location != window.location
        ? document.referrer
        : document.location;

    let params = new URL(String(url)).search;
    let searchParams = new URLSearchParams(params);

    let theme = "";
    if (searchParams.has("theme")) {
      theme = `&theme=${searchParams.get("theme")}`;
    }

    window.open(
      `${base_rc_research_ui_url}?postcode=${postcode}${theme}`,
      "_blank"
    );
  };

  return (
    <Formik
      initialValues={{ postcode: null }}
      onSubmit={(v) => onSubmit(v.postcode)}
      validationSchema={PostcodeValidationSchema}
    >
      {(props: FormikProps<{ postcode: string }>) => (
        <S.Form>
          <S.Fields>
            <label>Postcode</label>
            <Input
              value={props.values.postcode}
              onChange={(e) => props.setFieldValue("postcode", e.currentTarget.value)}
            />
            <S.Error>
              <ErrorMessage name="postcode" />
            </S.Error>
          </S.Fields>
          <Button type="submit" onClick={() => props.submitForm()}>
            Go
          </Button>
        </S.Form>
      )}
    </Formik>
  );
};

export { PostcodeSearchPage };

